import { useMemo } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ProtectedRoute } from "lib";
import { AppRoot } from "app/routes/app/root";

export const createAppRouter = () =>
  createBrowserRouter([
    {
      path: "/",
      lazy: async () => {
        const { Home } = await import("./routes/home");
        return { Component: Home };
      },
    },
    {
      path: "/login",
      lazy: async () => {
        const { LoginRoute } = await import("./routes/auth/login");
        return { Component: LoginRoute };
      },
    },
    {
      path: "/register",
      lazy: async () => {
        const { RegisterRoute } = await import("./routes/auth/register");
        return { Component: RegisterRoute };
      },
    },
    {
      path: "/verify/:code",
      lazy: async () => {
        const { VerifyRoute } = await import("./routes/auth/verify");
        return { Component: VerifyRoute };
      },
    },
    {
      path: "/app",
      element: (
        <ProtectedRoute>
          <AppRoot></AppRoot>
        </ProtectedRoute>
      ),
      children: [
        {
          path: "",
          lazy: async () => {
            const { Dashboard } = await import("./routes/app/dashboard");
            return { Component: Dashboard };
          },
        },
        {
          path: "/app/profile",
          lazy: async () => {
            const { Profile } = await import("./routes/app/profile");
            return { Component: Profile };
          },
        },
        {
          path: "/app/applications",
          lazy: async () => {
            const { Applications } = await import("./routes/app/applications");
            return { Component: Applications };
          },
        },
        {
          path: "/app/chat",
          lazy: async () => {
            const { Chat } = await import("./routes/app/chat");
            return { Component: Chat };
          },
        },
        {
          path: "/app/chat/:chatId",
          lazy: async () => {
            const { Chat } = await import("./routes/app/chat");
            return { Component: Chat };
          },
        },
        {
          path: "/app/property/:propertyId",
          lazy: async () => {
            const { Property } = await import("./routes/app/property");
            return { Component: Property };
          },
        },
        {
          path: "/app/create/property",
          lazy: async () => {
            const { CreatePropertyRoute } = await import(
              "./routes/app/create_property"
            );
            return { Component: CreatePropertyRoute };
          },
        },
      ],
    },
    {
      path: "/dashboard",
      lazy: async () => {
        const { RegisterRoute } = await import("./routes/auth/register");
        return { Component: RegisterRoute };
      },
    },
    {
      path: "*",
      lazy: async () => {
        const { NotFoundRoute } = await import("./routes/not-found");
        return { Component: NotFoundRoute };
      },
    },
  ]);

export const AppRouter = () => {
  // const queryClient = useQueryClient();

  const router = useMemo(() => createAppRouter(), []);

  return <RouterProvider router={router} />;
};
