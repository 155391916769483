import {
  BaseUserType,
  PropertyAddValues,
  SignupFormValuesRenter,
  SignupFormValuesTenant,
  TenantFormValues,
} from "types";
import { SignupFormBaseValues } from "types";
import { StatusEnum } from "utils";

export const baseUserMandatory: BaseUserType = {
  email: "",
  password: "",
  confirmPassword: "",
};

const baseUser = (status: number | ""): SignupFormBaseValues => ({
  status,

  profilepic: null,
  name: "",
  age: "",
  language: "",
  location: "",
  gender: "",
  ...baseUserMandatory,
});

const baseProperty: PropertyAddValues = {
  title: "",
  location: "",
  rent: "",
  securityDeposit: "",
  roomSize: "",
  roommates: "",

  gender: "",
  age: 40,

  moveIn: "",
  moveInDate: "",
  moveOut: "",
  moveOutDate: "",
  contract: "",

  roommateDetails: [],

  roomPhotos: null,

  extraDetails: [],
  extraDescription: "",
  bus_by: "",
  cc_by: "",
  uni_by: "",
  bus_min: "",
  cc_min: "",
  uni_min: "",
};

const baseTenant: TenantFormValues = {
  smoke: "",
  cleanliness: "",
  pets: "",
  socialize: "",
  cook: "",
  budget: "",
  city: "",
  moveIn: "",
  moveInDate: "",
  moveOut: "",
  moveOutDate: "",
  occupation: "",
  phoneNumber: "",
  photoHighlights: null,
  descriptions: [],
  bio: "",
};

export const initialValuesSignup = (
  s: number | "",
  directRegister: boolean
): SignupFormValuesRenter | SignupFormValuesTenant | { user: BaseUserType } => {
  if (s === StatusEnum.Renter) {
    return {
      user: baseUser(StatusEnum.Renter),
      property: baseProperty,
    };
  } else if (s === StatusEnum.Tenant) {
    if (directRegister) {
      return { user: baseUserMandatory };
    }
    return {
      user: baseUser(StatusEnum.Tenant),
      tenantData: baseTenant,
    };
  } else {
    throw new Error("Failed");
  }
};

export const initialValuesCreateProperty = () => ({
  property: baseProperty,
});
