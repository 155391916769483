import { Dispatch, SetStateAction } from "react";
import { createContext } from "react";

// if true tenant will be able to register without filling out any details
export interface DirectRegisterContextInterface {
  directRegister: boolean;
  setDirectRegister: Dispatch<SetStateAction<boolean>>;
}

const defaultState = {
  directRegister: false,
  setDirectRegister: (v: boolean) => {},
} as DirectRegisterContextInterface;

export const DirectRegisterContext = createContext(defaultState);
