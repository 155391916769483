import { useContext, useState } from "react";
import { AllPagesContext } from "context";
import { ActivePageContext } from "context";
import { DirectRegisterContext } from "context/directRegisterContext";

export const useCurrentPage = () => {
  let { allPages, setAllPages } = useContext(AllPagesContext);
  let { directRegister, setDirectRegister } = useContext(DirectRegisterContext);
  let { activePage: activePageIndex, setActivePage } =
    useContext(ActivePageContext);
  let activePage = allPages[activePageIndex];

  return {
    directRegister,
    setDirectRegister,
    allPages,
    setAllPages,
    activePageIndex,
    setActivePage,
    ...activePage,
  };
};
