import { FormikValues, useFormikContext } from "formik";
import { VStack, Text } from "@chakra-ui/react";

import { EnumButton } from "components/molecules";
import { enumToObject, splt } from "utils";
import { useCurrentPage } from "lib";
import { LogoWithName } from "components/organisms";

export const EnumPage = (): JSX.Element => {
  let {
    activePageIndex,
    setActivePage,
    enum_val,
    sideText,
    fields,
    setDirectRegister,
  } = useCurrentPage();

  let { setFieldValue, values }: FormikValues = useFormikContext();

  const saveFieldAndNext = (en: number) => {
    setFieldValue(fields[0], en);
    if (fields[0] === "tenantData.pre" && en === 1) {
      setDirectRegister(true);
      setActivePage(activePageIndex + 11);
    } else {
      setActivePage(activePageIndex + 1);
    }
  };

  if (enum_val) {
    return (
      <>
        <LogoWithName noName={true} size="70px" />
        <Text fontSize={"2xl"} py={2} textAlign={"center"} textStyle={"bold"}>
          {sideText}
        </Text>
        <VStack spacing={4}>
          {Object.keys(enumToObject(enum_val)).map((en, i) => (
            <EnumButton
              key={`${i}-${en}`}
              textStyle="light" // Apply the predefined text style
              width="300px"
              bg={
                parseInt(values[splt(fields[0], 0)][splt(fields[0], 1)]) ===
                parseInt(en)
                  ? "green.600"
                  : "none"
              }
              color={
                parseInt(values[splt(fields[0], 0)][splt(fields[0], 1)]) ===
                parseInt(en)
                  ? "white"
                  : "black.800"
              }
              onClick={() => saveFieldAndNext(parseInt(en))}
            >
              {enum_val ? enum_val[en as any] : ""}
            </EnumButton>
          ))}
        </VStack>
      </>
    );
  } else {
    return <></>;
  }
};
